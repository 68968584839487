import { createReducer } from "@reduxjs/toolkit"
import {
  createQuotationAction,
  createQuotationError,
  createQuotationSuccess,
  getQuotationAction,
  getQuotationActionError,
  getQuotationSuccess,
} from "ducks/currentQuotation/actions"
import { getApiClient } from "../api"
import {
  saveBevQuotationError,
  saveBevQuotationSuccess,
  getBevQuotation,
  getBevQuotationError,
  getBevQuotationSuccess,
} from "ducks/bevQuotation/actions"

import {
  abandonBevQuotation,
  reenableBevQuotation,
} from "ducks/bevQuotation/userStatus"
import { Path, redirectToUri } from "utils/router"
import { modifyBevQuotationVintage } from "ducks/bevProductData/vintage"
import { modifyBevQuotationVolume } from "ducks/bevProductData/productInfo/volume"
import { modifyBevQuotationConditioning } from "ducks/bevProductData/productInfo/conditioning"
import {
  getZoneListError,
  getZoneListSuccess,
  getZoneListAction,
} from "ducks/fields/zone"
import {
  getDomainListError,
  getDomainListSuccess,
  getDomainListAction,
} from "ducks/fields/domain"

import {
  getCuveeListError,
  getCuveeListSuccess,
  getCuveeListAction,
} from "ducks/fields/cuvee"
import { serializeError } from "serialize-error"
import { selectSearchedBevProductData } from "../ducks/bevProductData"
import _ from "lodash"
import {
  getQuotationsList,
  getQuotationsListError,
  getQuotationsListSuccess,
} from "../ducks/quotations"

import { setBevProductData } from "../ducks/bevProductData/actions"

const debouncedChangeFieldHandler = ({
  getFieldList,
  getFieldListSuccess,
  getFieldListError,
  action,
  dispatch,
}) => {
  const { payload } = action
  const { shouldFetchList = true } = payload

  if (shouldFetchList) {
    getFieldList(payload)
      .then(response => dispatch(getFieldListSuccess({ response })))
      .catch(error =>
        dispatch(getFieldListError({ error: serializeError(error) })),
      )
  }
}

const changeFieldFactory = args => () => debouncedChangeFieldHandler(args)

export default store => next => action => {
  next(action)
  const { dispatch, getState } = store
  const state = getState()
  const { user } = state
  const env = process.env.REACT_APP_MATCHA_ENV

  const apiClient = getApiClient({ user, env })

  const handleSaveBevQuotation = () => {
    const {
      payload: { currentQuotationId, bevQuotation },
    } = action

    const cleanedBevQuotation = _.omit(bevQuotation, [
      "bevProductData.pricings",
      "hasTooMuchSearchedBev",
    ])

    apiClient
      .saveBevQuotation({
        currentQuotationId,
        bevQuotation: cleanedBevQuotation,
      })
      .then(response => dispatch(saveBevQuotationSuccess({ response })))
      .catch(error =>
        dispatch(saveBevQuotationError({ error: serializeError(error) })),
      )
  }

  const handleApi = createReducer(null, {
    [createQuotationAction]: () => {
      const {
        payload: { currentQuotation },
      } = action
      const { name, rawInput, id } = currentQuotation

      apiClient
        .createQuotation({ id, name, rawInput })
        .then(response => {
          dispatch(createQuotationSuccess({ response }))
          return redirectToUri({
            path: Path.quotationResult,
            currentQuotationId: id,
          })
        })
        .catch(error => {
          dispatch(createQuotationError({ error: serializeError(error) }))
          return redirectToUri({
            path: Path.newQuotationWithId,
            currentQuotationId: id,
          })
        })
    },
    [setBevProductData]: handleSaveBevQuotation,
    [selectSearchedBevProductData]: handleSaveBevQuotation,
    [abandonBevQuotation]: handleSaveBevQuotation,
    [reenableBevQuotation]: handleSaveBevQuotation,
    [modifyBevQuotationVintage]: handleSaveBevQuotation,
    [modifyBevQuotationVolume]: handleSaveBevQuotation,
    [modifyBevQuotationConditioning]: handleSaveBevQuotation,
    [getQuotationAction]: () => {
      const {
        payload: { currentQuotationId },
      } = action

      apiClient
        .getQuotation(currentQuotationId)
        .then(response => dispatch(getQuotationSuccess({ response })))
        .catch(error =>
          dispatch(getQuotationActionError({ error: serializeError(error) })),
        )
    },
    [getZoneListAction]: changeFieldFactory({
      getFieldList: apiClient.getZones,
      getFieldListSuccess: getZoneListSuccess,
      getFieldListError: getZoneListError,
      action,
      dispatch,
    }),
    [getDomainListAction]: changeFieldFactory({
      getFieldList: apiClient.getDomains,
      getFieldListSuccess: getDomainListSuccess,
      getFieldListError: getDomainListError,
      action,
      dispatch,
    }),
    [getCuveeListAction]: changeFieldFactory({
      getFieldList: apiClient.getCuvees,
      getFieldListSuccess: getCuveeListSuccess,
      getFieldListError: getCuveeListError,
      action,
      dispatch,
    }),
    [getQuotationsList]: () => {
      apiClient
        .getQuotations()
        .then(response => dispatch(getQuotationsListSuccess({ response })))
        .catch(error =>
          dispatch(getQuotationsListError({ error: serializeError(error) })),
        )
    },
    [getBevQuotation]: () => {
      const {
        payload: { currentQuotationId, bevQuotationId },
      } = action

      apiClient
        .getBevQuotation({ currentQuotationId, bevQuotationId })
        .then(response => dispatch(getBevQuotationSuccess({ response })))
        .catch(error =>
          dispatch(getBevQuotationError({ error: serializeError(error) })),
        )
    },
  })

  handleApi(state, action)
}
