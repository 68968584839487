import _ from "lodash"
export const getIndexedObjectList = list => ({
  byId: _.keyBy(list, "id"),
  allIds: _.map(list, "id"),
})

export const simpleCombineReducers = reducers => (state, action) => ({
  ...state,
  ..._.reduce(
    reducers,
    (parentState, childReducer, childReducerName) => {
      const childState = state ? state[childReducerName] : undefined

      return {
        ...parentState,
        [childReducerName]: childReducer(childState, action),
      }
    },
    {},
  ),
})

export const immutableSet = (object, path, value) =>
  _.set(_.cloneDeep(object), path, value)
